<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col cols="8">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Acenta Kayıtları</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="myHr">
            <table class="table table-hover table-striped table-sm mt-1">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}">
                  ACENTA
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}">
                  KOD
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}">
                  RENK
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in this.$store.getters.acentaList"
                  :key="index"
                  class="text-center"
              >
                <td>{{ item.acenta }}</td>
                <td>{{ item.kod }}</td>
                <td>
                  <div
                      class="myColor"
                      :style="{ backgroundColor: item.renk }"
                  />
                </td>
                <td>
                  <b-button
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="odaGuncelle(item)"
                  >
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click="odaRemove(item)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col cols="4">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">{{
                      isEdit == false
                          ? 'Acenta kaydı'
                          : editName + ' isimli acenta düzenleniyor'
                    }}</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="myHr">
            <b-form-group
                label="Acenta Adı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="departmanName"
                  placeholder="Acenta Adını Giriniz"
                  @keyup.enter="!isEdit ? odaSave() : odaUpdate()"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="Acenta Kodu"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="acentaKodu"
                  placeholder="Acenta Kodunu Giriniz"
                  autocomplete="off"
                  @keyup.enter="!isEdit ? odaSave() : odaUpdate()"
              />
            </b-form-group>
            <b-form-group
                label="Acenta Rengi"
                label-for="basicInput"
                class="mt-1"
            >
              <LvColorpicker
                  :key="count"
                  v-model="hexCode"
                  hide-palette
              />
            </b-form-group>
            <b-row class="odaKayitRow">
              <b-button
                  v-show="isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgDanger"
                  class="mt-1 mr-1 border-0 text-white"
                  :style="{backgroundColor:$store.getters.bgDanger}"
                  @click="editClosed"
              >
                Vazgeç
              </b-button>
              <b-button
                  v-show="isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="odaUpdate"
              >
                Kaydet
              </b-button>
              <b-button
                  v-show="!isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="odaSave"
              >
                Kaydet
              </b-button>
            </b-row>
            <b-row :class="{ editOnPrgoress: isEdit }"/>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import LvColorpicker from 'lightvue/color-picker'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCardActions,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    LvColorpicker,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      hexCode: '#000000',
      isEdit: false,
      editName: '',
      departmanName: '',
      acentaKodu: '',
      updateID: '',
      count: '',
    }
  },
  created() {
    this.$store
        .dispatch('acentaVeri')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          if (mesaj) {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearAcenta')
  },
  methods: {
    odaSave() {
      this.$store
          .dispatch('acentaSave', {
            acentaName: this.departmanName,
            acentaKod: this.acentaKodu,
            renk: this.hexCode,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store.dispatch('departman');
            (this.departmanName = ''),
                (this.acentaKodu = ''),
                (this.hexCode = '#000000'),
                (this.count += 1)
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    odaUpdate() {
      this.$store
          .dispatch('acentaUpdate', {
            acentaName: this.departmanName,
            acentaKod: this.acentaKodu,
            renk: this.hexCode,
            acentaID: this.updateID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.isEdit = false;
            (this.departmanName = ''),
                (this.updateID = ''),
                (this.editName = ''),
                (this.acentaKodu = ''),
                (this.hexCode = '#000000'),
                (this.count += 1)
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    odaGuncelle(item) {
      this.isEdit = true
      this.departmanName = item.acenta
      this.editName = item.acenta
      this.acentaKodu = item.kod
      this.hexCode = item.renk
      this.updateID = item.acentaID
      this.count += 1
    },
    editClosed() {
      this.isEdit = false
      this.departmanName = ''
      this.updateID = ''
      this.acentaKodu = ''
      this.hexCode = '#000000'
    },
    odaRemove(val) {
      this.$store
          .dispatch('acentaRemove', { acentaID: val.acentaID })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
}
</script>

<style scoped>
.lv-colorpicker-wrapper {
  margin-top: -20px;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
  /* animation-iteration-count: 1 !important; */
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td[data-v-199ebc45][data-v-199ebc45] {
  text-align: center;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

[dir] .table th,
[dir] .table td {
  padding: 3px !important;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #31526f;
  color: white;
  border: none;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
  padding: 3px !important;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
}

.table th,
.table td {
  padding: 0 !important;
}

.card-body {
  padding: 10px 15px;
}

.myHr {
  margin-top: 0 !important;
}
</style>
<style>
.content-wrapper {

}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
